// BOOTSTRAP 4 VARIABLES

@import "components/bootstrap4/functions";
@import "components/bootstrap4/variables";
@import "components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "components/bootstrap4/root";
@import "components/bootstrap4/reboot";
@import "components/bootstrap4/type";
@import "components/bootstrap4/images";
@import "components/bootstrap4/code";
@import "components/bootstrap4/grid";
@import "components/bootstrap4/tables";
@import "components/bootstrap4/forms";
@import "components/bootstrap4/buttons";
@import "components/bootstrap4/transitions";
@import "components/bootstrap4/dropdown";
@import "components/bootstrap4/button-group";
@import "components/bootstrap4/input-group";
@import "components/bootstrap4/custom-forms";
@import "components/bootstrap4/nav";
@import "components/bootstrap4/navbar";
@import "components/bootstrap4/card";
@import "components/bootstrap4/breadcrumb";
@import "components/bootstrap4/pagination";
@import "components/bootstrap4/badge";
@import "components/bootstrap4/jumbotron";
@import "components/bootstrap4/alert";
@import "components/bootstrap4/progress";
@import "components/bootstrap4/media";
@import "components/bootstrap4/list-group";
@import "components/bootstrap4/close";
@import "components/bootstrap4/toasts";
@import "components/bootstrap4/modal";
@import "components/bootstrap4/tooltip";
@import "components/bootstrap4/popover";
@import "components/bootstrap4/carousel";
@import "components/bootstrap4/spinners";
@import "components/bootstrap4/utilities";
@import "components/bootstrap4/print";
// LAYOUT

@import "layout/layout";

// UTILS
@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";
@import "utils/animate_helper";

// ELEMENTS
@import "elements/buttons";
@import "elements/dropdown";
@import "elements/navs";
@import "elements/badges";
@import "elements/cards";
@import "elements/tabs";
@import "elements/accordions";
@import "elements/modals";
@import "elements/loaders";
@import "elements/progressbar";
@import "elements/timeline";
@import "elements/listgroup";
@import "elements/forms";
@import "elements/pagination";
@import "elements/chat";
@import "elements/forms-wizard";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";
@import "widgets/profile-boxes/profile-boxes";

// PAGES

@import "pages/userpages";

// Applications

@import "applications/applications-base";

// COMPONENTS

// Perfect Scrollbar
@import "components/perfect-scrollbar/perfect-scrollbar";

// RC Tabs
@import "components/rc-tabs/rc-tabs";

// Loading Buttons
@import "components/loading-buttons/loading-buttons";

// Hamburger Button
@import "components/hamburgers/hamburgers";

// Drawer
@import "components/drawer/drawer";

// Notifications
@import "components/notifications/sweetalerts";
// @import "components/notifications/toastify";

// Modals
@import "components/modals/rodal";

// Progress Bar
@import "components/progress-bar/progress-bar";

// Slick Carousel
@import "components/slick-carousel/slick/slick";

// Block loading
@import "components/block-loading/block-loading";

// Popovers & Tooltips
@import "components/popovers-tooltips/popovers-tooltips";

// Sticky Elements
@import "components/sticky-elements/sticky-elements";

// Calendar
@import "components/calendar/calendar";

// Splitters
@import "components/splitters/splitters";

// Tree View
@import "components/tree-view/tree-view";
@import "components/tree-view/sponsor-tree";


// Tables
@import "components/tables/tables";

// Icons
@import "components/icons/linearicons";
@import "components/icons/p7icons";

// Page TItle
@import "components/page-title/page-title";

// FORMS

@import "components/forms/datepicker/datepicker";
@import "components/forms/rangeslider/rangeslider";
@import "components/forms/multiselect/multiselect";
@import "components/forms/wysiwyg/wysiwyg";
@import "components/forms/toggle-switch/switch";
@import "components/forms/dropzone/dropzone";
@import "components/forms/typeahead/typeahead";

// REACT WIDGETS

@import "components/react-widgets/scss/react-widgets";

// DEMO

@import "demo-ui/demo";

// Responsive

@import "layout/responsive/responsive-base";

// Containers Style
@import "containers/login-page";
@import "containers/dashboard";

// Client Style
@import './ufit';
@import './lbb';
@import './winna';

.modal-dialog {
  max-width: 85vw;
}

input[type],
select.form-control,
.form-control,
.filepond--root {
  font-size: inherit;
}

.ant-btn, .ant-steps-item-icon{
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}

.custom-color:hover {
  filter: brightness(0.85)
}

.custom-color:disabled {
  opacity: 0.5;
}

.custom-color:disabled:hover {
  filter: brightness(1);
  opacity: 0.5;
}

.techbase-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background: rgba( 0, 0, 0, 0.3 );
}

.card-overlay-button {
  padding: 25px 30px;
  color: #fff;
  border: none;
}

.square-image-container{
  padding-top: 100%;
  overflow: hidden;
  position: relative;
  border: 1px solid #dddbdb;

  img{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translate(0, -50%);
  }
}

.item-summary > .row {
  padding: 8px;
  border-style: solid;
  border-width: 0 1px;
  border-color: #cccccc;
  align-items: center;
}

.item-summary > .row:first-child {
  border-top-width: 1px;
}

.item-summary > .row {
  border-bottom-width: 1px;
}

.checkout-list >.row {
  padding: 8px 0;
  border-style: solid;
  border-width: 1px 0 0;
  border-color: #efefef;
}

.checkout-list >.row:first-child {
  border-top-width: 0;
}

.package-details .variant-button {
  flex-direction: row;
  @media screen and (min-width: 1140px) {
    flex-direction: column;
  }
}

.pre-white-space .ant-select-selection-item {
  white-space: pre;
}

.ant-picker-time-panel-column::-webkit-scrollbar {
  display: none;
}

.ant-table-content {
  overflow-x: scroll; ;
}