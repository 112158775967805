.combination-container {
  border: 1px dashed #dfdfdf;
  border-radius: 20px;

  .combination-type-container {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ant-select,
    .ant-input-number {
      width: 100%;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
